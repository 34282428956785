.search-bar-wrap {
  display: flex !important;
  position: relative !important;
  width: 100% !important;
  border: 1px solid black;
  padding: 5px;
  border-radius: 9px !important;
  
  .search-icon {
    display: flex;
    align-items: center;
    margin-left: 15px;
    font-weight: 100 !important;
    font-size: 20px !important;
    @media screen and (max-width: 1640px) {
      font-size: 18px !important;
    }
    @media screen and (max-width: 1400px) {
      font-size: 18px !important;
    }
  }
  .select-icon {
    position: absolute !important;
    left: 3px !important;
    padding: 0 5px !important;
    top: 0px !important;
    font-weight: 100 !important;
    font-size: 23px !important;
    border-right: 1px solid #dfdfdf !important;
    @media screen and (max-width: 1640px) {
      font-size: 21px !important;
    }
    @media screen and (max-width: 1400px) {
      font-size: 21px !important;
    }
  }
  .ant-input-affix-wrapper {
    &:focus {
      border: 0 !important;
    }
    width: 90% !important;
    border: 0 !important;
    margin-left: 10px !important;
  }
  .ant-input-affix-wrapper .custom-search-bar {
    font-size: 15px !important;
    padding: 6px 45px !important;
    background-color: #938888 !important;
    &:focus-visible {
      outline: 1px solid #dfdfdf !important;
    }
    @media screen and (max-width: 1640px) {
      // font-size: 13px;
    }
  }
}
