.ant-modal-content{
    border-radius: 25px !important;
    .ant-modal-close{
        display: none;
    }
    .ant-modal-header{
        border-radius: 25px 25px 0 0;
        background-color: #00722D;
        .ant-modal-title{
            color: #fff;
        }
    }
}
.ant-modal-title{
    font-family: 'calibri-bold';
    font-size: 1.5rem;
}
.ant-modal-body{
font-size: 1.5rem;
padding: 30px;
}