.ant-pagination{
    text-align: center;
    .ant-pagination-item-link{
        border: 0;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        .anticon{
            display:flex;
            justify-content:center;
            align-items:center;
        }
    }
    .ant-pagination-item{
        border: 0;
    }
    .ant-pagination-item-active{
        background-color: #00722D;
        color: #fff;
        border: 1px solid #00722D;
    }
}