.custom-form-input-wrap {
  width: 100%;
  margin: 12px 0;

  &.invalid-pass {
    border-bottom: 1px solid #ff0606 !important;

    Input {
      &::placeholder {
        color: #fff !important;
        border-bottom: 1px solid #fff !important;
      }
    }
  }

  label {
    font-size: 1.5rem !important;
    color: #fff;
    // font-weight: 600;
  }

  Input {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    width: 100% !important;
    font-size: 14px;
    border-radius: 0;
    padding: 4px 0 !important;
    background-color: transparent !important;
    color: #fff !important;

    &::placeholder {
      color: #fff !important;
    }
  }

  .ant-picker,
  .ant-input-number,
  .ant-select {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    width: 100% !important;
    padding: 0;
    font-size: 14px !important;
    border-radius: 0;
    background-color: transparent;

    .ant-select-selector {
      border: none !important;
      height: 30px !important;
    }

    &.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
      .ant-select-selector {
      padding: 0 !important;
      background-color: transparent;

      &::placeholder {
        color: #fff !important;
      }
    }

    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      box-shadow: none !important;
    }

    &::placeholder {
      color: #fff !important;
    }

    .ant-select-arrow {
      height: 20px;
      width: 20px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: #91bc3d;
      font-size: 11px;
    }
  }

  .ant-picker-suffix {
    color: #fff;
  }
}

.custom-form-input-wrap2 {
  position: relative;
  margin: 10px 0;
  min-height: 30px;

  label {
    font-size: 18px;
    font-family: 'calibri-bold' !important;
  }

  Input {
    border: 1px solid #b8b8b8;
    border-radius: 8px;
    outline: none !important;
    box-shadow: none !important;
    width: 100% !important;
    font-size: 14px;
    padding: 6px !important;
    &:hover,
    :focus {
      border: 1px solid #00722d;
    }
    &::placeholder {
      color: #b8b8b8 !important;
    }
  }

  .ant-select {
    border: 1px solid #b8b8b8;
    border-radius: 8px;
    outline: none !important;
    box-shadow: none !important;
    width: 100% !important;
    font-size: 14px;
    padding: 2px !important;

    &::placeholder {
      color: #1f1f25 !important;
    }

    .ant-select-selector {
      height: 30px !important;
      border-radius: 8px;
      border: 0 !important;
      padding: 4px 8px !important;
      display: flex;
      align-items: center;

      .ant-select-selection-placeholder {
        line-height: 1.5715 !important;
        text-align: left;
      }
    }
  }

  .ant-picker {
    border: 1px solid #b8b8b8;
    border-radius: 8px;
    outline: none !important;
    box-shadow: none !important;
    width: 100% !important;
    font-size: 14px;
    padding: 6px !important;

    &::placeholder {
      color: #1f1f25 !important;
    }

    Input {
      border: 0 !important;
      padding: 0 !important;
      width: 100%;
    }

    .ant-picker-suffix {
      color: #1f1f25 !important;
      margin-right: 6px;
    }
  }

  .email-icon {
    position: absolute;
    right: -35px;
    top: -2px;
    font-size: 22px;
    color: #52a1d9;
  }
  .revert-icon {
    position: absolute;
    right: -35px;
    top: -2px;
    font-size: 22px;
    color: #52a1d9;
  }
}

.ant-select-dropdown {
  border-radius: 20px !important;
  border: 1px solid #b8b8b8 !important;
  background-color: #fff;

  .ant-select-item {
    .ant-select-item-option-content {
      padding: 3px;
    }
  }
}
.custom-required-after {
  label:after {
    display: inline-block;
    margin-left: 7px;
    color: red;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: ' *';
  }
}

.mods-select {
  .ant-select {
    .ant-select-selector {
      height: 36px !important;
    }
  }
}

.select-value-red {
  .SelectInput__Div-sc-locphc-0 {
    .float-label {
      .ant-select.custom-float-select.ant-select-single.ant-select-show-arrow {
        .ant-select-selector {
          .ant-select-selection-item {
            color: red;
          }
          .ant-select-selection-placeholder {
            color: red;
          }
        }
      }
    }
  }
}
.select-value-grey {
  .SelectInput__Div-sc-locphc-0 {
    .float-label {
      .ant-select.custom-float-select.ant-select-single.ant-select-show-arrow {
        .ant-select-selector {
          .ant-select-selection-item {
            color:#999999;
          }
          .ant-select-selection-placeholder {
            color: #999999;
          }
        }
      }
    }
  }
}
.greyed-out{
  color: #999999;
}
