.custom-green-btn {
  background-color: #00722D;
  color: #fff;
  border: 1px solid #00722D;
  padding: 7px 10px;
  border-radius: 8px;
  transition: all 0.3s;
  font-size: 14px;
  font-weight: 500;
  min-width: 70px;
  height: unset !important;

  &:hover {
    background-color: #00722D !important;
    color: #fff;
    border: 1px solid #00722D;
    box-shadow: 0 0.5em 0.5em -0.4em #707070;
    transform: translateY(-5px);
    transition: color 0.5s;
    transition: transform 0.7s;
  }

  &:focus {
    background-color: #00722D;
    color: #fff;
    border: 1px solid #00722D;
    box-shadow: 0 0.5em 0.5em -0.4em #707070;
    transition: color 0.5s;
    transition: transform 0.7s;
  }

  &:focus-visible {
    outline: none;
  }
  &.bg-btn {
    min-width: 80px;
  }

  @media screen and (max-width:1640px) {
    // font-size: 12px;
    // padding:5px 10px;
  }
}

.custom-red-btn {
  background-color: #d40202;
  color: #fff;
  border: 1px solid #d40202;
  padding: 7px 0px;
  border-radius: 8px;
  transition: all 0.3s;
  font-size: 14px;
  font-weight: 500;
  min-width: 70px;
  height: unset !important;

  &:hover {
    background-color: #d40202 !important;
    color: #fff;
    border: 1px solid #d40202;
    box-shadow: 0 0.5em 0.5em -0.4em #707070;
    transform: translateY(-5px);
    transition: color 0.5s;
    transition: transform 0.7s;
  }

  &:focus {
    background-color: #d40202;
    color: #fff;
    border: 1px solid #d40202;
    box-shadow: 0 0.5em 0.5em -0.4em #707070;
    transition: color 0.5s;
    transition: transform 0.7s;
  }

  &:focus-visible {
    outline: none;
  }

  @media screen and (max-width:1640px) {
    // font-size: 12px;
    // padding:5px 10px;
  }

  &.bg-btn {
    min-width: 80px;
  }
}

.custom-select-wrapper {
  background-color: #00722D;
  color: #fff;
  border: 1px solid #00722D;
  padding: 6px 14px;
  border-radius: 8px;
  transition: all 0.3s;
  display: flex;

  @media screen and (max-width:1640px) {
    font-size: 12px;
    padding: 6px 4px;
  }

  .custom-green-select {
    background-color: transparent;
    transition: all 0.3s;
    border: none;
    font-weight: 500;
    padding: 0 10px;
    cursor: pointer;

    option {
      color: #1F1F25;
      padding: 5px 10px;
    }

    &:focus-visible {
      outline: none;
    }

    @media screen and (max-width:1740px) {
      padding: 0 4px;
    }

    

  }
}

.custom-white-btn {
  background-color: #fff;
  color: #00722D;
  border: 1px solid #1F1F25;
  padding: 7px 14px;
  border-radius: 8px;
  transition: all 0.3s;
  font-size: 12px;
  font-weight: 500;
  min-width: 70px;
  height: unset !important;

  &:hover {
    border: 1px solid #00722D;
    color: #00722D;
    box-shadow: 0 0.5em 0.5em -0.4em #707070;
    transform: translateY(-5px);
    transition: all 0.7s;
  }

  @media screen and (max-width:1640px) {
    // font-size: 12px;
    // padding:5px 10px;
  }
}

.ant-radio-group {
  width: 100%;

  .ant-radio-wrapper {
    min-height: 90px;
    height: 100%;
    width: 100%;
    padding: 8px 16px;
    border: 1px solid #B8B8B8;
    border-radius: 14px;
    display: flex;
    align-items: center;
    margin: 10px 0px !important;

    .ant-radio-inner {
      top: -3px;
    }

    .img-wrapper {
      margin-left: 96px;

      @media screen and (max-width:1400px) {
        margin-left: 30px;
      }
    }
  }
}