    @import "/src/app/components/scss/buttons";
    @import "/src/app/components/scss/SearchBar";
    @import "/src/app/components/scss/table";
    @import "/src/app/components/scss/input";
    @import "/src/app/components/scss/fonts";
    @import "/src/app/components/scss/reset";
    @import "/src/app/components/scss/pagination";
    .ant-table-row {
        .ant-tag {
            border-radius: 8px;
            padding: 7px 12px;
            color: #fff;
            font-size: 12px;
            min-width: 170px;
            text-align: center;
            @media screen and (max-width: 1640px) {
                font-size: 10px;
                padding: 4px 8px;
                min-width: 120px;
            }
            &.ant-tag-geekblue {
                background-color: #369DF2;
            }
            &.ant-tag-green {
                background-color: #00722D;
            }
            &.ant-tag-yellow {
                background-color: #FFDC3D;
                color: #1F1F25 !important;
            }
            &.ant-tag-red {
                background-color: #f21400;
            }
        }
    }
    
    .dropdown-option-geekblue {
        background-color: #369df21b;
    }
    
    .dropdown-option-green {
        background-color: #00722e20;
    }
    
    .dropdown-option-yellow {
        background-color: #ffdb3d12;
        color: #1F1F25 !important;
    }
    
    .dropdown-option-red {
        background-color: #f214000f;
    }
    
    .ant-layout-content {
        width: 100% !important;
        .payment-summary-wrap {
            border: 1px solid #00722D;
            border-radius: 10px;
            padding: 20px;
            .detail-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 10px 0;
                .title {
                    color: #878787;
                }
                .amount {
                    color: #878787;
                }
                &.total {
                    margin-top: 50px;
                    .title {
                        color: #1F1F25;
                    }
                    .amount {
                        color: #1F1F25;
                    }
                }
            }
        }
    }
    
    .submit-height {
        height: 112px !important;
    }
    
    .add-lead-table-wrap {
        padding: 50px;
        background-color: #91BC3D;
        -webkit-box-shadow: 0px 0px 10px #0000000D;
        box-shadow: 0px 0px 10px #0000000D;
    }
    
    .lead-table-header {
        padding: 12px 25px;
        width: 100%;
        border-radius: 4px 4px 0 0;
        color: #fff;
        font-size: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    
    .lead-table-header .table-header-icon {
        font-size: 20px;
        margin-right: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    
    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
        color: #00722d !important;
    }
    
    .ant-table-column-title {
        white-space: nowrap !important;
    }
    
    .email-link {
        text-decoration: none;
        color: #2D2D2D !important;
        white-space: nowrap;
    }
    
    .ant-table-cell {
        color: #2D2D2D !important
    }
    
    .custom-msg {
        margin-top: 70vh !important;
        text-align: left !important;
        margin-Left: 20%
    }
    
    .delete-icon {
        color: red !important;
        font-size: 1.4rem
    }
    
    .btn-filter {
        border: 1px solid #00722D;
        opacity: 0.8 !important;
        font-size: 1rem !important;
        width: 95%;
    }
    
    .custom-btn-green {
        color: #fff !important;
        background-color: #00722D !important
    }
    
    .custom-card {
        border: 1px solid #00722D;
        border-radius: 10px;
        padding: 20px;
        width: 12%;
        background-color: #e7f3ce;
        div {
            height: 35px;
        }
        h2 {
            margin-top: 1rem;
            font-size: 1.1rem;
        }
    }
    
    .card-blue {
        background-color: #1b72e8;
    }
    
    .font-white {
        color: #fff;
    }
    
    .activeusers {
        background-color: #91BC3D !important;
    }
    
    .custom-not-data {
        height: 70vh;
        width: 90%;
        opacity: .6;
    }
    
    .custom-tooltip {
        border: 1px solid #00722D;
        border-radius: 10px;
        background-color: #e7f3ce;
        padding: 1rem
    }
    
    a {
        font-family: 'calibri' !important;
    }
    
    .ant-menu-title-content a {
        font-family: 'calibri-bold' !important;
    }
    
    .ant-menu-item-selected {
        background-color: #00722D !important;
    }
    
    body {
        font-family: 'calibri' !important;
    }
    
    button {
        font-family: 'calibri-bold' !important;
    }
    
    .recharts-cartesian-axis-tick {
        font-family: 'calibri-bold';
    }
    
    .chart-spinner {
        top: 30% !important;
    }
    
    .ant-tooltip-inner {
        color: #111 !important;
        background-color: #f7f7f7 !important;
        padding: 13px !important;
        font-size: 15px !important;
        border: 1px solid #00722D !important;
        border-radius: 10px !important;
    }
    
    .ant-tooltip-placement-bottom .ant-tooltip-arrow,
    .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
    .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
        border-bottom-color: green !important;
    }
    
    .text-area-width {
        width: 83% !important
    }
    
    .lebel-width {
        width: 17% !important
    }
    
    .ant-table-thead {
        position: sticky;
        top: 0;
        height: 5vh;
        z-index: 1;
        background-color: #fff
    }
    
    .bold-font {
        font-weight: bold;
    }
    
    .table-scroll {
        overflow-y: scroll;
        height: 70vh
    }
    
    .edit-icon-styles {
        width: 30px;
    }
    
    .edit-icon-styles-all {
        width: 15px;
    }
    
    .contact_input {
        padding: 5px !important;
    }
    
    .edit-input {
        margin: 0;
        width: 250px
    }
    
    .edit-actions {
        width: 70px;
        align-items: flex-end;
        font-size: 1.5rem
    }
    
    .edit-check-icon {
        margin-left: 10px;
        color: #91BC3D !important
    }
    
    .edit-cancel-icon {
        color: red;
        margin-left: 10px
    }