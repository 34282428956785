.ant-table-wrapper {
  .ant-table {
    z-index: 0;
    .ant-table-thead {
      th {
        border-bottom: 0;
        background-color: transparent;
        font-size: 20px;
        padding: 13px;
        font-family: 'calibri-bold';
        white-space:pre-wrap;
        text-overflow: ellipsis;
        &::before{
          height: 0 !important;
        }
        @media screen and (max-width:1740px) {
          font-size: 16px;
          padding:13px 6px;
        }
        @media screen and (max-width:1400px) {
          font-size:14px;
          padding:13px 4px;
        }
      }
    }

    .ant-table-tbody {

      td {
        font-size: 18px;
        border-bottom: 0;
        padding: 13px;
        font-family: 'calibri';
        white-space:pre-wrap;
        text-overflow: ellipsis;
        @media screen and (max-width:1740px) {
          font-size: 14px;
          padding:13px 6px;
        }
        @media screen and (max-width:1400px) {
          font-size: 13px;
          padding:13px 4px;
        }
      }
  
    }
  }
}
td.ant-table-column-sort {
  background: transparent !important;
}
.ant-table-column-sorter{
  display: none;
}
th.ant-table-cell-fix-right-first{
  right:0px !important
}
.users-table-wrapper{
  tr{
    cursor: pointer;
  }
}
