
#components-layout-demo-fixed-sider .logo {
    height: 32px;
    margin: 16px;
    background: #91BC3D;
  }
  .site-layout .site-layout-background {
    background: #fff;
  }

  .ant-menu.ant-menu-dark .ant-menu-item-selected.customclass {
    background-color: #00722D; /*Overriden property*/
  }

