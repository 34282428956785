*{
    box-sizing: border-box;
}
body{
    padding: 0;
    margin: 0;
    font-family: calibri !important;
    font-size: 16px;
    color: #2D2D2D !important;
    background-color: #fff !important;
    }
h1{
    font-size: 36px;
}
h2{
    font-size: 32px;
}
h3{
    font-size: 28px;
}
h4{
    font-size: 24px;
}
h5{
    font-size: 20px;
}
h6{
    font-size: 18px;
}
h1,
h2,
h3,
h4,
h5,
h6{
    font-family: calibri-bold !important;
}
p{
    font-family: calibri !important;
}
label{
    font-family: calibri-bold !important;
}
ul{
    padding: 0;
    margin: 0;
    li{
        list-style-type: none;
        font-family: calibri-bold !important;
    }
}
a{
    color: #4394FF;
    font-family: calibri-bold !important;
    text-decoration: none;
}
img{
    max-width: 100%;
}
.ant-layout{
    background-color: #fff !important;
}